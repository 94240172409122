<template>
    <v-container>
        <!-- 标题输入 -->
        <v-row>
            <v-col>
                <v-text-field v-model="title" :disabled="isEditMode" label="标题" required></v-text-field>
            </v-col>
        </v-row>

        <!-- 分类选择 -->
        <v-row>
            <v-col>
                <v-select
                    v-model="selectedCategories"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="分类"
                    multiple
                    clearable
                ></v-select>
            </v-col>
        </v-row>

        <!-- 标签输入 -->
        <v-row>
            <v-col>
                <v-combobox v-model="tags" label="标签" multiple chips></v-combobox>
            </v-col>
        </v-row>

        <!-- 富文本编辑器内容输入 -->
        <v-row>
            <v-col>
                <quill-editor ref="editor" v-model="content" :options="editorOptions"></quill-editor>
            </v-col>
        </v-row>

        <!-- 提交按钮 -->
        <v-row>
            <v-col>
                <v-btn color="primary" @click="submitRecord">保存条目</v-btn>
            </v-col>
        </v-row>

        <!-- 成功提示 -->
        <v-snackbar v-model="snackbar" color="success">{{ snackbarText }}</v-snackbar>
    </v-container>
</template>

<script>
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import service from '@/utils/request';

export default {
    name: 'RecordEditor',
    components: {
        quillEditor
    },
    data() {
        return {
            id: null,
            title: '',
            content: '',
            tags: [], // 标签列表
            selectedCategories: [], // 选中的分类 ID 列表
            categories: [],
            viewCount: 0,
            isEditMode: false,
            snackbar: false,
            snackbarText: '',
            editorOptions: {
                modules: {
                    toolbar: [
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'color': [] }, { 'background': [] }],
                        ['link', 'blockquote', 'code-block'],
                        ['image']
                    ],
                },
                theme: 'snow'
            }
        };
    },
    created() {
        this.loadCategories();
        const id = this.$route.params.id;
        const title = this.$route.query.title;
        if (id) {
            this.id = id;
            this.isEditMode = true;
            this.loadRecord(id);
            this.loadTags(id);
        }else {
            this.title = title || '';
        }
    },
    methods: {
        loadCategories() {
            service.get('/wikiapi/categories')
                .then(response => {
                    this.categories = response.data;
                })
                .catch(error => console.error("Error loading categories:", error));
        },
        loadRecord(id) {
            service.get(`/wikiapi/record`, { params: { id } })
                .then(response => {
                    const record = response.data;
                    if (record && record.title) {
                        this.title = record.title;
                        this.content = record.content;
                        this.viewCount = record.viewCount || 0;
                        this.selectedCategories = record.categories.map(category => category.id);
                    } else {
                        this.isEditMode = false;
                        this.snackbarText = "未找到该条目，将创建新条目";
                        this.snackbar = true;
                    }
                })
                .catch(error => {
                    console.error("Error loading record:", error);
                    this.snackbarText = "加载条目失败，请重试。";
                    this.snackbar = true;
                });
        },
        loadTags(id) {
            service.get(`/wikiapi/record/tags`, { params: { id } })
                .then(response => {
                    this.tags = response.data;
                })
                .catch(error => {
                    console.error("Error loading tags:", error);
                    this.snackbarText = "加载标签失败，请重试。";
                    this.snackbar = true;
                });
        },
        submitRecord() {
            if (!this.title.trim()) {
                this.snackbarText = "标题不能为空";
                this.snackbar = true;
                return;
            }

            const payload = {
                id: this.id,
                title: this.title,
                content: this.content,
                viewCount: this.viewCount,
                categoryIds: this.selectedCategories, // 选中的分类ID列表
                tags: this.tags
            };

            service.post('/wikiapi/record', payload)
                .then(response => {
                    const recordId = response.data.id || this.id; // 使用返回的ID或已有的ID
                    this.snackbarText = "条目保存成功！";
                    this.snackbar = true;

                    // 短暂延迟后跳转到条目页
                    setTimeout(() => {
                        this.$router.push({ name: '维基条目', params: { id: recordId } });
                    }, 1000);
                })
                .catch(error => {
                    console.error("Error saving record:", error);
                    this.snackbarText = "保存失败，请重试。";
                    this.snackbar = true;
                });
        }
    }
};
</script>

<style scoped>
/* 富文本编辑器样式和页面保持一致 */
</style>